import React,{useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import '../assets/css/OurLoverStory.css'
import Title from './Section-Title';

const OurLovestory = () => {
    const [wow, setWow] = useState(new WOW.WOW())
    useEffect(() => {
        setWow(wow.init())
    }, []);
    return(
        <>
            <section className="love_story section-padding" id="story">
                <Container>
                    <Title sec_title={'Invitation'}/>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="story-timeline">
                                <div className="round-shape"></div>
                                <div className="row order_column">
                                    <div className="col col-lg-6 col-12 wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                        <div className="story-text right-align-text">
                                            <span className="date">Chers famille et amis,</span>
                                            <p>C'est avec une immense joie que Monsieur et Madame SI MOHAMMED vous invitent à célébrer le mariage de leurs fils Adem, Nous serons honorés de partager ce moment de bonheur avec vous, Votre présence embellira cette journée et ajoutera à la richesse de nos souvenirs. Nous avons hâte de célébrer cet amour avec vous !</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default OurLovestory;
